import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

// 公共路由
 const constantRoutes = [
    { path: '/', component: () => import('../components/login.vue')},
    { path: '/main', component: () => import('../components/main.vue')},
    { path: '/pdf', component: () => import('../components/pdf.vue')},
    { path: '/markdown', component: () => import('../components/markdown.vue')},
];


export default new Router({
    mode: "history", // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
});
