<template>
  <div class="login">
    <img src="../assets/logo.png" alt="" style="margin-right:10px" width="207" height="47" />
    <section class="login_content">
        <section class="login_container" style="margin-top:30px;">
          <img alt="" src="../assets/input_username.png" width="17" height="20" style="margin-left:12px" />
          <el-input style="flex:1" placeholder="请输入账号" v-model="username" :disabled="loading" size="small"></el-input>
        </section>
        <section class="login_container">
          <img alt="" src="../assets/input_password.png" width="17" height="20" style="margin-left:12px" />
          <el-input style="flex:1" placeholder="请输入密码" v-model="password" type="password" :disabled="loading" size="small"></el-input>
        </section>
        <section class="login_submit" @click="submitClick" v-loading="loading">
          <span>{{ loading ? '登 录 中...' : '登录' }}</span>
        </section>
    </section>
  </div>
</template>

<script>
import {login} from '../api'
import {setToken} from '../auth'
var Base64 = require('js-base64').Base64

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return{
      username:'',
      password:'',
      loading:false
    }
  },
  methods:{
    submitClick(){
      if(!this.loading){
        if(this.username && this.password){
          this.loading = true
          let temp = {username:this.username.trim(),password: Base64.encode(this.password)}
          login(temp).then(res => {
            if (res.code === "0000") {
              setToken(res.data.token);
              this.$router.push('/main')
              this.loading = false
            }
          }).catch(()=>{
            this.loading = false
          })

        }else{
          let errTitle = '请输入'
          if(!this.username){
            errTitle += '账号'
          }
          if(!this.password){
            errTitle += '、密码'
          }
          this.$message({
            type:'warning',
            offset:1,
            message:errTitle,
          });
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.login_content{
  background-image: url("../assets/login_content.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 300px;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

}
.login_container{
  background-image: url("../assets/input.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 238px;
  height: 36px;
  display: flex;
  align-items: center;
}
.login_submit{
  background-image: url("../assets/submit.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 238px;
  height: 36px;
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:30px;
}
/deep/ .el-input__inner{
  background-color: transparent !important;
  border:none;
  padding:0 15px 0 10px;
}
</style>
