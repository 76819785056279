import request from "./request";
import {setToken} from "@/auth";

// 登录方法
export function login(data) {
    return request({
        url: "/userOrg/api/org/login",
        method: "post",
        data: data,
    });
}

export function getKnowLedge(data) {
    return request({
        url: '/itsmApp/api/knowledge/index',
        method: 'post',
        data: data
    })
}
export function knowledgeChat(id,question,fileId) {
    return request({
        url: `/itsmApp/api/knowledgeFile/chat`,
        method: 'post',
        params:{knowledgeId:id,question:question,fileId:fileId}
    })
}
export function knowledgeChatLogDir(data) {
    return request({
        url: `/itsmApp/api/knowledgeFile/messages`,
        method: 'post',
        data:data
    })
}



