import axios from 'axios'
import { Notification, Message } from 'element-ui'
import { getToken } from './auth'
import router from './router'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: window.g.baseUrl,
  // 超时
  timeout: 300000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers["token"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    const code = res.data.code;
    if (code == "1000") {
        router.push('/')
        return res.data;
    }else if (code !== "0000") {
      Notification.error({title: res.data.msg,});
      if (res.config.url.indexOf("login") != -1) {
        return res.data;
      }
      return Promise.reject(res.data);
    } else {
      return res.data;
    }
  },
  (error) => {
    if (axios.isCancel(error)) {
      // 使用isCancel 判断是否是主动取消请求
      return new Promise(() => { });
    } else {
      Message({
        message: error.message,
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
  }
);

export default service;
